import { createRouter, createWebHistory } from "vue-router";
import store from "./store/index";

const routes = [
  {
    path: "/",
    name: "Home Page",
    component: () => import("@/views/home/index"),
    meta: {
      layout: "main",
    },
  },
  {
    path: "/login",
    name: "Login Page",
    component: () => import("@/views/auth/login"),
    meta: {
      layout: "auth",
    },
  },
  {
    path: "/instant-buy/:product_id",
    name: "Instant Buy",
    component: () => import("@/views/auth/instant_buy"),
    meta: {
      layout: "auth",
    },
  },
  {
    path: "/instant-buy/success/confirmation",
    name: "Instant But Confirmation Page",
    component: () => import("@/views/auth/instant_buy_confirmation"),
    meta: {
      layout: "auth",
    },
  },
  {
    path: "/register",
    name: "Register Page",
    component: () => import("@/views/auth/register"),
    meta: {
      layout: "auth",
    },
  },
  {
    path: "/product",
    name: "Product Page",
    component: () => import("@/views/product/index"),
    meta: {
      layout: "main",
    },
  },
  {
    path: "/product/:product_id",
    name: "Detail Product Page",
    component: () => import("@/views/product/detail"),
    meta: {
      layout: "main",
    },
  },
  {
    path: "/cart",
    name: "Cart Page",
    component: () => import("@/views/cart/index"),
    meta: {
      layout: "main",
    },
  },
  {
    path: "/checkout",
    name: "Checkout Page",
    component: () => import("@/views/checkout/index"),
    meta: {
      layout: "main",
    },
  },
  {
    path: "/appointment/:product_id",
    name: "Appointment Page",
    component: () => import("@/views/appointment/index"),
    meta: {
      layout: "main",
    },
  },
  {
    path: "/footer/:footer_detail_id",
    name: "Footer Detail Page",
    component: () => import("@/views/footer/detail"),
    meta: {
      layout: "main",
    },
  },
  {
    path: "/panel",
    name: "Panel Page",
    meta: {
      layout: "main",
    },
    component: () => import("@/views/panel/base.vue"),
    children: [
      {
        path: "profile",
        name: "Profile",
        meta: {
          menu: "profile",
        },
        component: () => import("./views/panel/profile.vue"),
      },
      {
        path: "appointment",
        name: "Appointment History",
        meta: {
          menu: "appointment",
        },
        component: () => import("./views/panel/appointment/index.vue"),
      },
      {
        path: "withdraw",
        name: "Withdraw",
        meta: {
          menu: "withdraw",
        },
        component: () => import("./views/panel/withdraw/index.vue"),
      },
      {
        path: "bank_account",
        name: "Akun Bank",
        meta: {
          menu: "bank_account",
        },
        component: () => import("./views/panel/bankAccount/index.vue"),
      },
      {
        path: "refferal_transaction",
        name: "Transaksi Refferal",
        meta: {
          menu: "refferal_transaction",
        },
        component: () => import("./views/panel/refferalTransaction/index.vue"),
      },
      {
        path: "order",
        name: "Order History",
        meta: {
          menu: "order",
        },
        component: () => import("./views/panel/order/index.vue"),
      },
      {
        path: "shipping_address",
        name: "Alamat Pengiriman",
        meta: {
          menu: "shipping_address",
        },
        component: () => import("./views/panel/shippingAddress/index.vue"),
      },
      {
        path: "order/:order_id",
        name: "Order Detail",
        meta: {
          menu: "order",
        },
        component: () => import("./views/panel/order/detail.vue"),
      },
      {
        path: "package_purchase",
        name: "Package Purchase",
        meta: {
          menu: "package_purchase",
        },
        component: () => import("./views/panel/packagePurchase/index.vue"),
      },
      {
        path: "package_purchase_history",
        name: "Histori Pembelian Paket",
        meta: {
          menu: "package_purchase_history",
        },
        component: () => import("./views/panel/packagePurchase/history.vue"),
      },
      {
        path: "package_purchase_history/:package_purchase_id",
        name: "Detail Histori Pembelian Paket",
        meta: {
          menu: "package_purchase_history",
        },
        component: () => import("./views/panel/packagePurchase/detail.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  store.dispatch("CANCEL_PENDING_REQUESTS");
  next();
});

export default router;
